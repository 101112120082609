.avatar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  z-index: 1000;
  min-width: 100px;
  :global {
    .imageContainer {
      width: 40px;
      height: 40px;
      align-items: center;
      display: flex;
      svg {
        width: 100%;
        height: 100%;
      }
      img {
        height: auto;
        width: 100%;
      }
    }
    span {
      margin-left: 4px;
      width: 100%;
      font-size: 1em;
    }
  }
}
.profile {
  :global {
    .wysiwyg {
      .sun-editor {
        border: none;
        .sun-editor-common {
          display:none;
        }
        .sun-editor-editable {
          overflow-y: hidden;
          overflow-x: hidden;
        }
      }
    }
    .sun-editor-editable {
      strong {
        font-weight: bold;
      }
    }
    .card {
      background: #f4f5f7 !important;
      .card-header {
        background: none;
        padding:0;
      }
      .col {
        padding-bottom: 20px !important;
        input[type=text] {
          box-shadow: 0 1px 1px RGB(50 50 93 / 15%), 0 1px 0 RGB(0 0 0 / 2%);
            &:focus {
              box-shadow: 0 1px 3px RGB(50 50 93 / 15%), 0 3px 0 RGB(0 0 0 / 2%);
            }
        }
        input[type=file] {
          display: none;
        }
        .upload {
          font-weight: normal;
          padding: 0;
          overflow:hidden;
          span {
            display: block;
            height: 100%;
            line-height: 3.1em;
            white-space: nowrap;
            float: left;
            &:first-child {
              cursor: pointer;
              border: 1px solid #3c7cff;
              color: #3c7cff;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              width: 12%;
              text-align: center;
              overflow:hidden;
              &:hover {
                background:#e0eaff;
              }
            }
            &:not(:first-child) {
              margin-left:10px;
            }
          }
        }
        h6 {
          color: #8898aa !important;
          font-size: 12px;
        }
        .igtr {
          border: 0;
          border-left: 1px solid #f0f2f3;
          border-top-left-radius: 0 0;
          border-bottom-left-radius: 0 0;
          box-shadow: 0 1px 1px RGB(50 50 93 / 15%), 0 1px 0 RGB(0 0 0 / 2%);
        }
        .sun-editor {
          overflow: hidden;
          border-radius: 0.35em !important;
          .se-navigation {
            display:none;
          }
          .se-toolbar {
            background: none;
            .se-btn-module {
              border: 0!important;
              &:not(:first-child) {
                ul svg {
                  color:#525f7f;
                }
              }
            }
          }
          .se-resizing-bar {
            border: 0;
            background: none;
            &:after {
              text-align: center;
              font-family: "Font Awesome 5 Free";
              font-size: 12px;
              margin-left: -10px;
              right: 5px;
              font-weight: 900;
              content: "\f338";
              line-height: 1.4;
              color: #525f7f;
              opacity: 0.2;
              position: absolute;
            }
          }
        }
        label {
          font-weight: bold;
          font-size: 14px;
          color:#525f7f;
        }
      }
      .picture {
        display: flex;
        justify-content: center;
        div {
          width:130px;
          height:130px;
          overflow:hidden;
          margin-top: -75px;
          position: relative;
          img {
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: center center;
          }
          svg {
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            position: absolute;
          }
        }
      }
      &.rightSide {
        min-width: 310px;
        >div {
          hr {
            padding: 0;
            margin: 0;
          }
          span {
            display: block !important;

          }
          .row:nth-child(2) {
            span {
              padding-bottom: 5px;
              &:nth-child(2n+1) {
                font-weight: bold;
              }
              &:nth-child(2),
              &:nth-child(3) {
                font-size:0.85em;
              }
            }
          }
        }
      }
      .wysiwyg {
        &.col {
          padding-bottom: 0 !important;
        }
      }
    }
  }
}