.password {
  :global {
    .error-warning {
      color: red;
      padding: 0;
      margin: 0;
      position: relative;
      margin-top:4px;
      span {
        display: inline-block;
        position: absolute;
        margin-left:auto;
        margin-right:auto;
        left:0;
        right:0;
      }
    }
  }
}