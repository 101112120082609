.filterElSize {
  max-width: 30% !important;
  min-width: 70px;
}

.filter {
  select,
  input,
  span,
  button {
    height: 22px;
    padding: 0;
    margin: 0;
  }

  >div>select {
    font-size: 12px;
    width: auto;
    max-width: 50%;
  }

  span {
    width: 20px;
    >i{
      width: 100%;
      height: 100%;
      background: transparent;
      display: inline-block;
      clip-path: polygon(50% 80%, 100% 20%, 0% 20%);
      margin-left:-2px;
      svg {
        transform: scaleX(2)
      }  
    }
    >svg {
      max-height: 70%;
      max-width: 70%;
    }
  }

  >div>div>input {
    padding-left: 4px;
    width:100%;
    padding:0;
    margin:0;
    border: 1px solid transparent;
    font-size: 12px;
  }

  input:focus {
    outline: none !important;
    box-shadow: none !important;
    border:1px solid white !important;
  }
}

.comsumptionPage :global {
  .consumptionChartCard {
    // display: none;
    &.first {
      .card-header {
        background: #1a174d;
        h5 {
          color: white;
        }
      }
      background: #1a174d;
    }
    button {
      &:not(.btn-primary) {
        background: white;
      }
      &:hover {
        background:rgb(115, 161, 253);
        border-color: rgb(75, 134, 253);
      }
    }
  }
  .consumptionCard {
    div.row {
      &:nth-child(3) {
        .col {
          &:first-child {
            .bg-default {
              background:#3c7cff!important;
            }
          }
          &:not(:first-child) {
           .progress-label {
              span {
                color: #29cc97;
              }
            }
            .bg-default {
              background:#29cc97!important;
            }
          }
        }
      }
    }
    h5 {
      font-size:12px;
      color: grey;
    }
    span.credit {
      font-weight: bold;
      font-size: 16px;
      color: black;
    }
    button {
      box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
      padding-top:2px;
      padding-bottom:2px;
      &:hover {
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
    h6 {
      color: grey;
      font-weight: normal;
      font-size: 12px;
    }
    .progress-wrapper {
      padding-top: 4px;
      .progress-label {
        width: 100%;
        span {
          padding-left: 8px;
          padding-right: 8px;
          text-align: center;
        }
      }
    }
    .consumption-bottom {
      span {
        font-size: 12px;
        font-weight: bold;
      }
      h5 {
        font-weight: normal;
        padding-bottom: 0px;
        margin-bottom:0px;
      }
    }
  }
}