.App {
    display: flex;
    box-sizing: border-box;
    /* height: 100%; */
    user-select: none;
    color: #525F7F;
    font-size: 12px;
    background-repeat: no-repeat;
}

.App:not(.back-office) {
  height: 100vh;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-full {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.emoji-mart-preview {
    display: none !important;
}

.small-text,
.small-text p {
    font-size: 10.5px !important;
}

.btn {
    font-size: 12px;
}

.hover-bold:hover {
    font-weight: bold;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    top: 20% !important;
    left: 20% !important;
    right: 20% !important;
    bottom: 20% !important;
}

form.password-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

form.password-form span {
    font-size: 24px;
    font-weight: bold;;
}

form.password-form input {
    font-size: 20px;
}

form.password-form button {
    font-size: 20px;
}

form.invalid .invalid-feedback {
    display: block
}

form.invalid .form-control, .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.message:not(:hover) > .message-remove-bt {
    display: none;
}

.accordion .card-header:after {
    content: ''
}

.simplebar-content {
    display: flex;
    flex-direction: column;
}
.streams .simplebar-content-wrapper,
.streams .simplebar-content {
    height: 100% !important;
    flex-direction: column;
}

.streams .simplebar-track.simplebar-vertical {
    width: 6px;
}

.streams .simplebar-track {
    right: -11px;
}

.streams .OT_root {
    margin: auto;
    width: 100% !important;
    height: 100% !important;
}

.form-control .react-date-picker__wrapper,
.form-control .react-time-picker__wrapper {
    border: none;
}

.event-thumbnail .event-coming-soon-bt {
    min-width: 140px;
}

.event-thumbnail .event-coming-soon-bt:before {
    content: "Prochainement";
}

.event-thumbnail.admin .event-coming-soon-bt:hover {
    background-color: #2dce89;
    border-color: #2dce89;
}

.event-thumbnail.admin .event-coming-soon-bt:hover:before {
    content: "Démarrer";
}

#vtopia-unity canvas {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

#notes-tab p, #notes-tab ol, #notes-tab ul, #notes-tab pre, #notes-tab blockquote, #notes-tab h1, #notes-tab h2, #notes-tab h3, #notes-tab h4, #notes-tab h5, #notes-tab h6 {
  font-size: 12px !important;
}

#notes-tab .notes-content:hover {
  background-color: #32325D11;
  cursor: pointer;
}

.sidebar {
    transition: all ease-in 0.35s;
}

.sidebar.collapsed {
    max-width: 80px !important;
}

.sidebar.collapsed button {
    margin-right: 0;
    margin-left: 0;
    width: 80px;
}

.viewer-list-item .avatar {
    position: relative;
}

.viewer-list-item .avatar::before {
    content: " ";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #29CC97;
    right: -10px;
}


div.badge {
    background-color: #3cb55b;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding: 4px 6px;
    line-height: 10px;
}

div.badge-login {
    background-color: #3cb55b;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding: 4px 6px;
}