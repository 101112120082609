:root {
    --first-color: transparent;
    --second-color: transparent;
    --third-color: transparent;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--second-color) !important;
}

#root {
    height: 100vh;
}

.bg-primary {
    background-color: #5173D1 !important;
}

.first-color {
    background: var(--first-color) !important;
}
.second-color {
    background: var(--second-color) !important;
}
.third-color {
    background: var(--third-color) !important;
}
.fill-default {
    fill: var(--third-color);
}
.gradient-background {
    background: var(--second-color); /* Old browsers */
    background: -moz-linear-gradient(top, var(--first-color) 0%, var(--first-color) 5%, var(--second-color)25%, var(--second-color) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, var(--first-color) 0%, var(--first-color) 25%, var(--second-color) 25%, var(--second-color) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, var(--first-color) 0%, var(--first-color) 25%, var(--second-color) 25%, var(--second-color) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5173D1', endColorstr='#F2E7DF', GradientType=0); /* IE6-9 */
}
.no-background {
    background: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}
