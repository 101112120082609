/* Root form */
.form>h5 {
  color:#a8b3c1 !important;
}

/* Switch Element */
.centerSwitch>label {
  margin-left: -1.5rem;
}
.centerSwitch>label::before {
  left:0 !important;
  width:34px !important;
  height:16px !important;
}
.centerSwitch>label::after {
  left:0 !important;
  width:12px !important;
  height:12px !important;
}
.centerSwitch :global(.custom-control-input:checked~.custom-control-label::after) {
  transform: translateX(1.35rem) !important;
}

/* Filter elements */
.filter select,
.filter input,
.filter span,
.filter button {
  height: 22px;
  padding: 0;
  margin: 0;
}
.filter span {
  width: 20px;
}
.filter>div>div>input {
  padding-left: 4px;
  width:100%;
  padding:0;
  margin:0;
  border: 1px solid transparent;
  font-size: 12px;
}
.filter input:focus {
  outline: none !important;
  box-shadow: none !important;
  border:1px solid white !important;
}
.filter span>i{
  width: 100%;
  height: 100%;
  background: transparent;
  display: inline-block;
  clip-path: polygon(50% 80%, 100% 20%, 0% 20%);
  margin-left:-2px;
}
.filter span>i svg {
  transform: scaleX(2)
}
.filter span>svg {
  max-height: 70%;
  max-width: 70%;
}
.filter>div>select {
  font-size: 12px;
  width: auto;
  max-width: 50%;
}
.filterElSize {
  max-width: 30% !important;
  min-width: 70px;
}

/* Generic */
.unified {
  display:flex;
  background: white;
  border: 1px solid #dee2e6;
  padding-right: 4px;
  padding-left: 2px;
  white-space: pre;
}
.unified button,
.unified span {
 box-shadow: none !important;
 border: none;
}
.unified button {
  color: #818385;
  font-weight: normal;
  font-size: 12px;
}
.ul {
  list-style: none;
  padding-left: 8px;
  width:100%;
}

/* thead style */
.thead {
  background:#f7fafc;
}

/* remove button */
.removeButton {
  padding: 0 !important;
  background: transparent !important;
  margin-right: -4px !important;
  width: 30px !important;
}

.collector_button {
  width: 13%;
  height: 24px;
  padding: 0 !important;
  font-size: 12px !important;
  font-weight: normal !important;
  max-width: 180px;
}

/* pagination */
.pagination a {
  width: 26px !important;
  height: 26px !important;
  font-size: 12px !important;
}

/* email-editor */
tbody>tr>td {
  vertical-align: middle !important;
}

.editor input[type=text],
.editor label {
  height:24px !important;
  padding:0 !important;
  margin:0 !important;
  border: 0 !important;
  border-bottom: 1px solid white !important;
}

.editor input[type=text] {
  background: none;
  box-shadow: unset;
  outline:0 none;
  border-radius: unset;
  border-bottom: 1px dashed rgb(223, 222, 222) !important;
}

.editor input[type=text]:focus {
  border-bottom: 1px dashed #3c7cff !important;
}

.editor label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
  width: 100%;
}

.hide {
  display: none !important;
}