.comAddTo {
  :global {
    .btn-primary {
      height: 40px !important;
      text-align: center;
    }
    .dropdown-menu {
      a {
        span {
          height: 40px !important;
          width: 40px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          svg {
            transform: scale(1.4) !important;
          }
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px !important;
          flex-direction: row !important;
          span {
            display: none;
            margin-left: 40px !important;
            display: inline-block;
          }
        }
      }
    }
    ul {
      li {
        hr {
          padding:0;
          margin:0;
          margin-top: 10px;
        }
        padding: 4px;
        padding-top: 8px;
        input {
          vertical-align: middle;
        }
        label {
            align-items: center;
            justify-content: left !important;
            margin-bottom: 0;
            display: inline-block;
        }
      }
    }
    .primary {
      .dropdown-toggle:focus {
        box-shadow: 0 2px 2px #9bbaf8, 0 1px 3px #9bbaf8 !important;
      }
      .dropdown-toggle, .btn-secondary:focus {
        color: #fff;
        background-color: #3c7cff;
        border-color: #3c7cff;
        font-weight: normal !important;
      }
      .dropdown-toggle {
        &:hover {
          color: #fff !important;
          background-color: #095aff!important;
          border-color: #3c7cff!important;
        }
      }
    }
    .secondary {
      .dropdown-toggle:focus {
        box-shadow: 0 2px 2px #bebebe, 0 1px 3px #d3d3d3 !important;
      }
      .dropdown-toggle, .btn-secondary:focus {
        color: #808080;
        background-color: #ffffff;
        border-color: #9b9b9b;
        font-weight: normal !important;
      }
      .dropdown-toggle {
        &:hover {
          color: #525252 !important;
          background-color: #a3a3a3!important;
          border-color: #808080!important;
        }
      }
    }
  }
}

.comCancelButton {
  border: 1px solid lightgrey !important;
  color: lightgrey;
  &:hover {
    color: rgb(173, 173, 173) !important;
  }
}

*.comSwitch {
  :global {
    margin-left: -2.0em;
    label {
      &::before {
        left: -2.0rem !important;
        top: 0 !important;
      }
      &::after {
        left: calc(-2rem + 2px) !important;
        top: 2px !important;
      }
    }
  }
}
.comUploadFile {
  :global {
    input[type=file] {
      display: none;
    }
    .upload {
      font-weight: normal;
      padding: 0;
      overflow:hidden;
      span {
        display: block;
        height: 100%;
        line-height: 3.1em;
        white-space: nowrap;
        float: left;
        &:first-child {
          cursor: pointer;
          border: 1px solid #3c7cff;
          color: #3c7cff;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          width: auto;
          max-width: 50%;
          text-align: center;
          overflow:hidden;
          padding-left:4px;
          padding-right:4px;
          &:hover {
            background:#e0eaff;
          }
        }
        &:not(:first-child) {
          margin-left:10px;
        }
      }
    }
  }
}
.comDropdown {
  :global {
    .form-control.btn {
      color: #8898aa !important;
      border: 1px solid #dee2e6 !important;
      background-color: white !important;
      padding-left:16px;
      padding-right:16px;
      &:focus {
        border-color: #3c7cff !important;
      }
    }
    ul {
      list-style: none;
    }
  }
}

.comGroupedBtn {
  :global {
    display:flex;
    background: white;
    border: 1px solid #dee2e6;
    white-space: pre;
    button, span {
    box-shadow: none !important;
    border: none;
    }
    span {
      width: 40px;
      background: none;
    }
    button {
      color: #818385;
      font-weight: normal;
      font-size: 12px;
      background: none !important;
      width: calc(100% - 40px);
      &:hover {
        background: none;
        color: white !important;
      }
      &:focus {
        color: inherit !important;
        &:hover {
          color: white !important;
        }
      }
      &.alone {
        width: 100% !important;
      }
    }
  }
  &:hover {
    background: #095aff;
    button {
      cursor: pointer;
    }
    span svg {
      color: #FFFFFF !important;
    }
  }
}

.comBadge {
  border-radius: 50%;
  font-weight: bold;
  color:#555;
  background:lightgrey;
  font-size: 11px !important;
  width: 24px !important;
  height: 24px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
}

.comDateTimeReadOnly {
  :global {
    input {
      border: none !important;
      &:focus {
        outline: none !important;
        border: none !important;
      }
      background: white !important;
    }
  }
}

.comQuillReadOnly {
  :global {
    .ql-container {
      .ql-editor {
        padding-left: 0;
        padding-right: 0;
        border: none !important;
        min-height: 0 !important;
        p {
          font-size:14px !important;
        }
      }
    }
  }
}

//
.comUnified {
    display:flex;
    background: white;
    border: 1px solid #dee2e6;
    padding-right: 4px;
    padding-left: 2px;
    white-space: pre;
  button, span {
   box-shadow: none !important;
   border: none;
  }
  button {
    color: #818385;
    font-weight: normal;
    font-size: 12px;
    background: none;
  }
}

.listToolBar {
  select,
  input,
  span,
  button {
    height: 22px;
    padding: 0;
    margin: 0;
  }

  >div>select {
    font-size: 12px;
    width: auto;
    max-width: 50%;
  }

  span {
    width: 20px;
    >i{
      width: 100%;
      height: 100%;
      background: transparent;
      display: inline-block;
      clip-path: polygon(50% 80%, 100% 20%, 0% 20%);
      margin-left:-2px;
      svg {
        transform: scaleX(2)
      }  
    }
    >svg {
      max-height: 70%;
      max-width: 70%;
    }
  }

  >div>div>input {
    padding-left: 4px;
    width:100%;
    padding:0;
    margin:0;
    border: 1px solid transparent;
    font-size: 12px;
  }

  input:focus {
    outline: none !important;
    box-shadow: none !important;
    border:1px solid white !important;
  }
}
// Global
.comBrand {
  :global {
    .navbar-brand {
      width: 100%;
      padding: 0.5em !important;
      height: 100%;
      div.image {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        // background-image: none !important;
        // background-color:red;
        .badge {
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(50%, 50%);
        }
      }
    }
  }
}

@import 'animate.module.scss';
@include fadeIn();
.comFadeIn {
  animation-fill-mode: forwards;
  @include animate(fadeIn, 0.5s, 0.0s);
}

@include spinning();
.comSpinningWhite, .comSpinning {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @include animate(spinning, 1.5s, 0.0s);
}
.comSpinningWhite {
  color: white !important;
}

.comBreak {
  word-wrap: break-word !important;
  max-width: 1px  !important;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto  !important;
  word-break: break-all !important;
  white-space: pre-wrap !important;
}

.comError {
  position: relative;
  height: 0px;
  span {
    width: 100%;
    display: inline-block;
    position: absolute;
    top: -10px;
    left: 0;
    height: 20px;
    color: rgb(255, 0, 34);
  }
}

.comButtonLoading, .comLoading {
  width: 100%;
  height: 91px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    height: 40px;
    width: 40px;
    svg {
      height: 100%;
      font-size: 30px;
      margin-top: -2px;
      opacity: 0.3;
    }
  }
}
.comButtonLoading {
  height: 43px;
  width: 120px;
  background-color: #3c7cff;
  border-radius: 5px;
}

.comSideBar {
  :global {
    .joint-closed {
      display: none;
    }
    .joined {
      a {
        i {
          color: rgb(230, 173, 16) !important;
        }
      }
    }
    .joint-footer {
      border-bottom: solid 1px #c3cdd8;
    }
    li {
      &.joint-header {
        border-top: solid 1px #c3cdd8;
        // border-bottom: solid 1px #c3cdd8;
        &.closed {
          border-bottom: solid 1px #c3cdd8;
        }
        button {
          border: none;
          padding-left: 1em !important;
          background: none;
          i {
            color: rgb(230, 173, 16)!important;
          }
          span {
            margin-left: 0.725em!important;
          }
        }
      }
    }
  }
}

.comTextItem {
  align-self: stretch !important;
  margin: 0 !important;
  padding: 5px;
  padding-bottom:1px;
  span {
    border-radius: 6px;
    border: 1px solid rgb(246, 246, 245);
    width:100%;
    display: flex;
    align-self: center;
    flex-grow: 1;
    background: white;
    height: 100%;
    padding:4px;
    padding-left: 8px;
    margin: 0;
    line-height: 30px;
  }
}

.addEventButton {
  width: 100%;
  height: 200px;
  border: solid 1px rgb(172, 181, 206);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 1.5em;
  margin-top:300px;
  color: rgb(172, 181, 206);
  cursor: pointer;
  &:hover {
    background:rgba(190, 199, 224, 0.068);
    border: solid 1px rgb(190, 199, 224) !important;
    color: rgb(190, 199, 224) !important;
  }
  :global {
    .aeb-content {
      text-align: center;
    }
    .aeb-icon {
      font-size: 2em;
      text-align: center;
    }
  }
}

.transition {
  -webkit-transition: all 1s 0.2s ease-in-out;
  -moz-transition: all 1s 0.2s ease-in-out;
  -o-transition: all 1s 0.2s ease-in-out;
  transition: all 1s 0.2s ease-in-out;
}
