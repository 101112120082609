.publisher-talking{
    height: 100%;
    position: relative;
    background: #bada55;

}

.shadow-speaker::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    box-shadow:inset -20px -12px 0px -15px rgb(81, 114, 227);
}

.subscriber-talking{
    height: 100%;
    position: relative;
    background: #bada55;
}
