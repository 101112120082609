@import 'components/common/animate.module';
@include keyframes(fadein) {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@include keyframes(fadeout) {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.filter {
  position: relative;
  hr {
    display: block;
    height: 1px;
    width: 100%;
    padding: 0;
    margin: 0;
    border:none;
    border-top: 1px solid rgb(221, 221, 221);
    background: none;
    // margin-top: 0px;
    // padding-bottom: 10px;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
  }
  h3 {
    padding-left: 10px;
    font-size: 14px;
    font-weight: normal;
    width:100%;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  select, input, span, button {
    height: 22px;
    padding: 0;
    margin: 0;
  }
  span {
    width: 20px;
  }
  >div>div>input {
    padding-left: 4px;
    width:100%;
    padding:0;
    margin:0;
    border: 1px solid transparent;
    font-size: 12px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
    border:1px solid white !important;
  }
  span>i {
    width: 100%;
    height: 100%;
    background: transparent;
    display: inline-block;
    clip-path: polygon(50% 80%, 100% 20%, 0% 20%);
    margin-left:-2px;
  }
  span>i svg {
    transform: scaleX(2)
  }
  span>svg {
    max-height: 70%;
    max-width: 70%;
  }
  >div>select {
    font-size: 12px;
    width: auto;
    max-width: 50%;
  }
  :global {
    .filterCollapsed {
      position: relative;
      display: flex;
      width: 200px;
    }
    .filter-el-size {
      max-width: 30% !important;
      min-width: 70px;
    }
    .panel {
      position: absolute;
      width: 200px;
      top: 30px;
      right: 0;
      background: white;
      border: 1px solid rgb(190, 190, 190);
      z-index:9999999;
      padding:10px;
      border-radius: 4px;
      &.row {
        padding:0 !important;
      }
      .col:first-child {
        .row:first-child {
          padding-top:8px;
          padding-bottom:8px;
          >span {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px !important;
          }
        }
      }
    }
    .fadein:local {
      animation-fill-mode: forwards;
      @include animate(fadein, 0.5s, 0.0s);
    }
    .fadeout:local {
      animation-fill-mode: forwards;
      @include animate(fadeout, 0.5s, 0.0s);
    }
  }

  .collapser {
    z-index: 1;
    display: block;
    width: 28px;
    height: 28px;
    border: none;
    position: absolute;
    top:-32px;
    right:5px;
    font-size:18px;
    line-height: 32px;
    font-family: Consolas;
    font-weight: bold;
    color:#BBB;
    border-radius: 50%;
    border: 1px solid rgb(233, 233, 233); 
    box-shadow: none !important;
    // background: #F5F5F5;
    text-align: center;
    span {
      transform: scaleX(1.25);
      display: inline-block;
      // vertical-align: center;
    }
  }
  .uncollapsed {
    transform: rotate(180deg) !important;
    background:white !important;
  }
  
}