.speaker-indicator {
    display: flex;
    justify-content: center;
}

.speaker-indicator span {
    display: inline-block;
    background-color: #6079fe !important;
    width: 6px;
    height: 9px;
    margin: 0 2px;
    border-radius: 30px;
    animation: none; /* Désactive l'animation par défaut */
}

.speaker-indicator .center {
    height: 14px;
}

.speaker-indicator.is-talking span {
    animation: speaker-animation 1s ease-in-out infinite; /* Ajoute l'animation si la classe is-talking est présente */
}

@keyframes speaker-animation {
    0% {
        opacity: 0.3;
        transform: scaleY(1);
    }
    10% {
        opacity: 1;
        transform: scaleY(1.5);
    }
    20% {
        opacity: 0.3;
        transform: scaleY(1);
    }
    30% {
        opacity: 1;
        transform: scaleY(1.3);
    }
    40% {
        opacity: 0.3;
        transform: scaleY(1);
    }
    50% {
        opacity: 1;
        transform: scaleY(1.2);
    }
    60% {
        opacity: 0.3;
        transform: scaleY(1);
    }
    70% {
        opacity: 1;
        transform: scaleY(1.4);
    }
    80% {
        opacity: 0.3;
        transform: scaleY(1);
    }
    90% {
        opacity: 1;
        transform: scaleY(1.1);
    }
    100% {
        opacity: 0.3;
        transform: scaleY(1);
    }
}
