@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animate($name, $duration, $delay) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
    -o-animation-name: $name;
      animation-name: $name;
  -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
      -o-animation-duration: $duration;
          animation-duration: $duration;
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
        animation-delay: $delay;
}

@mixin spinning() {
  @include keyframes(spinning) {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.25;
    }
    100% {
      transform: rotate(359deg);
      opacity: 1;
    }
  }  
}

@mixin fadeIn() {
  @include keyframes(fadeIn) {
    0% {
      height: 0%;
      opacity: 0;
    }
    20% {
      height: 0%;
      opacity: 0;
    }
    100% {
      height: 100%;
      opacity: 1;
    }
  }  
}
