.report>div {
  background: #f4f5f7 !important
}
.report input[type=email],
.report input[type=phone],
.report input[type=text],
.report textarea {
  box-shadow: 0 1px 1px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}
.report input[type=email]:focus,
.report input[type=phone]:focus,
.report input[type=text]:focus,
.report textarea:focus {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 3px 0 rgb(0 0 0 / 2%);
}
.report h6 {
  color:#8898aa !important;
  font-size:12px;
}
/* Input Group Text*/
.report .igtr {
  border: 0;
  border-left: 1px solid #f0f2f3;
  border-top-left-radius: 0 0;
  border-bottom-left-radius: 0 0;
  box-shadow: 0 1px 1px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}