.filterElSize {
  max-width: 30% !important;
  min-width: 70px;
}

.collapser {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid lightgrey;
  border-radius: 30px;
  position: absolute;
  top:10px;
  right:10px;
  font-size:18px;
  line-height: 28px;
  font-family: Consolas;
  font-weight: bold;
  color:#BBB;
  box-shadow: none !important;
  transform: rotate(0deg);
  background: #F5F5F5;
  filter: blur(0.6px);
  span {
    transform: scaleX(1.25);
    display: block;
  }
}
.uncollapsed {
  transform: rotate(180deg) !important;
  background:white !important;
  filter: blur(0px) !important;
}
.renderAnswers {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  // border-top: 1px dashed grey;
  >div {
    /* border: 1px solid black; */
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    &:not(:first-child) {
      padding-top:40px;
    }
    >div {
      /* border: 1px solid blue; */
      display: flex;
      flex-grow: 1;
      max-width: 33% !important;
      white-space: pre-wrap !important;
    }
  }
  :global {
    .answer {
      display: flex;
      flex-direction: column !important;
      position: relative;
      span {
        display: block;
        position: absolute;
        right: calc(20% + 2px);
        top: 2px;
      }
      .progress>.progress-bar {
        background-color: #3cd0a0 !important;
      }
      >div:not(:first-child) {
        padding-top:4px;
        >div {
          width:calc(80% - 2px);
          height:4px;
          margin-left:2px;
        }
      }
    }
  }
}
.statisticsPage :global {
  .docList {
    table {
      th:first-child, td:first-child {
        text-align: left !important;
      }
    }
  }
  .answerList {
    table {
      th, td {
        text-align: left !important;
        position: relative;
      }
    }
  }
  .card {
    &.panel-info {
      .card-header {
        padding-bottom: 0 !important;
        .badge {
          position: absolute;
          right:0;
          display:block;
          height:40px;
          width:40px;
          background: #1a174d;
          border-radius: 50%;
          >path {
            color: white;
            transform: scale(0.74) translateX(18%) translateY(22%);
          }
        }
      }
      .card-body  {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        b {
          font-size: 14px;
        }
      }
      .card-footer {
        padding-top: 10px !important;
        span {
          &:nth-child(2) {
            margin-left:4px;
            color: #3fbc91;
          }
        }
        svg {
          >path {
            color: #3fbc91;
          }
        }
      } 
    }
    div.row {
      &:nth-child(3) {
        .col {
          &:first-child {
            .bg-default {
              background:#3c7cff!important;
            }
          }
          &:not(:first-child) {
           .progress-label {
              span {
                color: #29cc97;
              }
            }
            .bg-default {
              background:#29cc97!important;
            }
          }
        }
      }
    }
    h5 {
      font-size:12px;
      color: grey;
    }
    button {
      box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
      padding-top:2px;
      padding-bottom:2px;
      &:hover {
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
    h6 {
      color: grey;
      font-size: 12px;
    }
    .progress-wrapper {
      padding-top: 4px;
      .progress-label {
        width: 100%;
        span {
          padding-left: 8px;
          padding-right: 8px;
          text-align: center;
        }
      }
    }
  }
}

.filter {
  select,
  input,
  span,
  button {
    height: 22px;
    padding: 0;
    margin: 0;
  }

  >div>select {
    font-size: 12px;
    width: auto;
    max-width: 50%;
  }

  span {
    width: 20px;
    >i{
      width: 100%;
      height: 100%;
      background: transparent;
      display: inline-block;
      clip-path: polygon(50% 80%, 100% 20%, 0% 20%);
      margin-left:-2px;
      svg {
        transform: scaleX(2)
      }  
    }
    >svg {
      max-height: 70%;
      max-width: 70%;
    }
  }

  >div>div>input {
    padding-left: 4px;
    width:100%;
    padding:0;
    margin:0;
    border: 1px solid transparent;
    font-size: 12px;
  }

  input:focus {
    outline: none !important;
    box-shadow: none !important;
    border:1px solid white !important;
  }
}
