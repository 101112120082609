@import 'components/common/animate.module';
@include keyframes(open) {
  0% {
    height: 0%;
    opacity: 0;
  }
  20% {
    height: 0%;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
.pharmaList :global {
  tbody {
    position: relative;
  }
  .download-button {
    height: 24px;
    padding: 0 !important;
    font-size: 12px !important;
    font-weight: normal !important;
    max-width: 180px;
    width: 50%;
  }
}

.detailPanel {
  position: absolute;
  width: 100%;
  height: 550px;
  top: 0;
  left: 20px;
  h5 {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: .75rem;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: #8898aa !important;
  }
  h6 {
    color: #525f7f;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.collapser {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid lightgrey;
  border-radius: 30px;
  font-size: 18px;
  line-height: 28px;
  font-family: Consolas;
  font-weight: bold;
  box-shadow: none !important;
  transform: rotate(0deg);
  background: #F5F5F5;
  filter: blur(0.6px);
  span {
    transform: scaleX(1.25);
    display: block;
  }
}
.uncollapsed {
  transform: rotate(180deg) !important;
  background:white !important;
  filter: blur(0px) !important;
}

// tr
.collapsed :global {
  position: relative;
  width: 100%;
  padding:0;
  margin:0;
  td {
    height: 100%;
    opacity: 1;
    position: absolute;
    width: 100%;
    // background: red;
    overflow: hidden;
  }
  .open:local {
    animation-fill-mode: forwards;
    @include animate(open, 0.3s, 0.0s);
  }
}
